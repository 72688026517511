import { Popup } from './Popup';

export class Notification extends Popup {
  constructor({popupSelector, popupActiveClass}, {notificationWrapperSelector, notificationTogglerSelector, handler}) {
    super({popupSelector, popupActiveClass}),
    this._handler = handler,
    this._isOpen = false,
    this._notification = document.querySelector(popupSelector),
    this._notificationWrapper = document.querySelector(notificationWrapperSelector),
    this._notificationTogglers = document.querySelector(notificationTogglerSelector)
  }

  handleNotification(handleOpenNotification, handleCloseNotification) {
    this._handleOpenNotification = handleOpenNotification;
    this._handleCloseNotification = handleCloseNotification;
  }

  setEventListeners() {
    if(this._notificationTogglers) {
      this._notificationTogglers.addEventListener('click', () => {
        !this._isOpen ? this.open() : this.close();
      });
    }
  }

  open() {
    super.open();
    this._isOpen = true,
    this._handler({
      notification: this._notification,
      notificationWrapper: this._notificationWrapper
    });
    this._handleOpenNotification();
  }

  close() {
    super.close();
    this._isOpen = false,
    this._handleCloseNotification();
  }
}
