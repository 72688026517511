import '../scss/main.scss';
import AOS from 'aos';

import $ from  'jquery';

import './blocks/slides';
import './blocks/tabs';
import './blocks/mask';
import './blocks/others';
import './blocks/practicle';
import './blocks/scroll-header';

import './blocks/menu';
import {mainNav} from './blocks/menu';

AOS.init({
	once: true
});

document.body.onload = function() {
    setTimeout(function() {
        const preloader = document.getElementById('page-preloader');
        if( !preloader.classList.contains('done'))
        {
            preloader.classList.add('done');
        }
    },3000);
}

$("promo__btn").on('click',function() {
	let goIndex = $(this).parent().index();
	swiperAbout.slideTo(goIndex);
	$(".promo__btn").removeClass("promo__btn_active");
	$(this).addClass("promo__btn_active");
	return false;
});



document.querySelectorAll('.menu__link').forEach(menuLinkEl=>{
    menuLinkEl.addEventListener("click",()=>{
        mainNav.close();
    })
    
});

Fancybox.bind('[data-fancybox]', {
    // Your custom options
  });