const tabs = (headerSelector, tabSelector, contentSelector, activeClass) => {
  const header = document.querySelector(headerSelector),
        tab = document.querySelectorAll(tabSelector),
        content = document.querySelectorAll(contentSelector);

  function hideTabContent() {
      content.forEach(item => {
          /*
          item.style.overflow = 'hidden';
          item.style.transition = '2s';
          item.style.opacity = '0';
          item.style.height = '0';
          */
          item.classList.remove('active');
      });

      tab.forEach(item => {
          item.classList.remove(activeClass);
      });
  }

  function showTabContent(i = 0) {
      //content[i].style.display = 'block';
      /*
      content[i].style.opacity = '1';
      content[i].style.overflow = 'visible';
      content[i].style.transition = 'all .5s';
      content[i].style.height = 'auto';
      */
    //   content[i].classList.add('active');
      if (content[i]) {
			content[i].classList.add("active");
		}
      if (tab[i]) {
			tab[i].classList.add(activeClass);
		}
  }

  hideTabContent();
  showTabContent();

  header.addEventListener('click', (e) => {
      const target = e.target;
      if (target &&
          (target.classList.contains(tabSelector.replace(/\./, "")) || 
      target.parentNode.classList.contains(tabSelector.replace(/\./, "")))) {
          tab.forEach((item, i) => {
              if (target == item || target.parentNode == item) {
                  hideTabContent();
                  showTabContent(i);
              }
          });
      }
  });
};

export default tabs;

window.addEventListener('DOMContentLoaded', () => {
//   tabs('.tabs-wrapper-js', '.tabs-btn-js','.tabs-content-js', 'active');
  tabs('.tabs-clients__js', '.tab-clients__item','.tabs-clients_content-js', 'active__colors');
//   tabs('.tabs-location__js', '.tab-location__item','.tabs_location_content-js', 'active__colors_green');
  tabs('.tabs-news__js', '.tab-news__item','.tabs_news_content-js', 'active__colors_green');
 //tabs('.project__tab_js', '.project__tab_nav','.project__tab_content', 'project__tab_nav-ative');
  tabs('.project__wrapper', '.tab-nav__item' ,'.project__content-tab', 'project__tab-lg-ative');
});