import $ from  'jquery';
import Swiper from 'swiper';


/* promo slides */
/*
*/
if(document.querySelector('.promo-swiper')) {
	const swiperAboutNav = new Swiper('.promo__content_nav ', {
		slidesPerView: 'auto',
		direction: 'vertical',
		breakpoints: {
			575: {
				direction: 'horizontal',
			},
			575: {
				direction: 'vertical',
			},
			768: {
				direction: 'vertical',
			},
			1366: {
				direction: 'vertical',
			}
		}
	});

	const swiperAbout = new Swiper('.promo-swiper', {
		spaceBetween: 30,
		effect: "fade",
		fadeEffect: {
			crossFade: true
		},
		autoplay: {
			delay: 2800,
		},
	    speed: 1000,
		thumbs: {
			swiper: swiperAboutNav
		},
		navigation: {
			prevEl: ".promo-prev",
			nextEl: ".promo-next",
		},
	});
}

/* promo slides mobile */
/*
*/
if(document.querySelector('.promo-swipers')) {
	const swiperAboutMobile = new Swiper('.promo__pagination', {
		slidesPerView: 'auto',
		direction: 'horizontal',
	});

	const swiperAbout = new Swiper('.promo-swipers', {
		spaceBetween: 30,
		effect: "fade",
		fadeEffect: {
			crossFade: true
		},
		autoplay: {
			delay: 3000,
		},
	    speed: 1000,
		thumbs: {
			swiper: swiperAboutMobile
		},
		navigation: {
			prevEl: ".promo-next",
			nextEl: ".promo-prev",
		},
	});
}
	let menuBtn = document.querySelector('.burger-header');
	let menu = document.querySelector('.burger-menu');
	let header = document.querySelector('.animate-bg');
	

	// menuBtn.addEventListener('click', function(){
	// 	menuBtn.classList.toggle('burger-menu_active');
	// 	menu.classList.toggle('burger-menu_active');
	// 	header.classList.toggle('fixed');
	// })

	//Tabs-slider News one
const swiperContent = new Swiper('.news__pagination_all', {
	slidesPerView: 'auto',
	direction: 'horizontal',
});

new Swiper('.swiper-content', {
	direction: 'horizontal',
    speed:100,
	spaceBetween: 16,
	loop:true,
	slidesPerView: 'auto',
	pagination: {
		el: '.swiper-pagination',
		type: 'custom',
		renderCustom: function (swiper, current, total) {
			return `<span class="swiper-pagination-current">${('0' + current).slice(-2)}  </span>/
			<span class="swiper-pagination-total"> ${('0' + total).slice(-2)} </span> `
		}
	},
	navigation: {
		nextEl: '.tab-news__content .swiper-nav__arrow-prev',
		prevEl: '.tab-news__content .swiper-nav__arrow-next',
	},
	thumbs: {
		swiper: swiperContent,
	},
	mousewheel: true,
	keyboard: true,
});

//Tabs-slider News two
const swiperContentTwo = new Swiper('.news__pagination_2', {
    slidesPerView: 'auto',
    direction: 'horizontal',
});

function initSwiperMain(){ 	
	new Swiper('.swiper-content-2', {
		direction: 'horizontal',
		speed:100,
		spaceBetween: 16,
		slidesPerView: 'auto',
		pagination: {
			el: '.swiper-pagination',
			type: 'custom',
			renderCustom: function (swiper, current, total) {
				return `<span class="swiper-pagination-current">${('0' + current).slice(-2)}  </span>/
				<span class="swiper-pagination-total"> ${('0' + total).slice(-2)} </span> `
			}
		},
		navigation: {
			nextEl: '.tab-news__content .swiper-nav__arrow-prev',
			prevEl: '.tab-news__content .swiper-nav__arrow-next',
		},
		thumbs: {
			swiper: swiperContentTwo,
		},
		mousewheel: true,
		keyboard: true,
		observer: true,
		observeParents: true,
	});
}

$(".init-swiper2").on('click', function(){
    initSwiperMain('#swiper2');
	//initSwiper2();
});

//Tabs-slider News three

const swiperContentThree = new Swiper('.news__pagination_3', {
    slidesPerView: 'auto',
    direction: 'horizontal',
});

function initSwiperMainThree(){ 	
	new Swiper('.swiper-content-3', {
		direction: 'horizontal',
		speed:100,
		spaceBetween: 16,
		loopedSlides: 1,
		slidesPerView: 'auto',
		pagination: {
			el: '.swiper-pagination',
			type: 'custom',
			renderCustom: function (swiper, current, total) {
				return `<span class="swiper-pagination-current">${('0' + current).slice(-2)}  </span>/
				<span class="swiper-pagination-total"> ${('0' + total).slice(-2)} </span> `
			}
		},
		navigation: {
			nextEl: '.tab-news__content .swiper-nav__arrow-prev',
			prevEl: '.tab-news__content .swiper-nav__arrow-next',
		},
		thumbs: {
			swiper: swiperContentThree,
		},
		mousewheel: true,
		keyboard: true,
		observer: true,
		observeParents: true,
	});
}

$(".init-swiper3").on('click', function(){
    initSwiperMainThree('#swiper3');
	//initSwiper2();
});

//Tabs-slider News four

const swiperContentFour = new Swiper('.news__pagination_4', {
    slidesPerView: 'auto',
    direction: 'horizontal',
});

function initSwiperMainFour(){ 	
	new Swiper('.swiper-content-4', {
		direction: 'horizontal',
		speed:100,
		loop:true,
		spaceBetween: 16,
		slidesPerView: 'auto',
		pagination: {
			el: '.swiper-pagination',
			type: 'custom',
			renderCustom: function (swiper, current, total) {
				return `<span class="swiper-pagination-current">${('0' + current).slice(-2)}  </span>/
				<span class="swiper-pagination-total"> ${('0' + total).slice(-2)} </span> `
			}
		},
		navigation: {
			nextEl: '.tab-news__content .swiper-nav__arrow-prev',
			prevEl: '.tab-news__content .swiper-nav__arrow-next',
		},
		thumbs: {
			swiper: swiperContentFour,
		},
		mousewheel: true,
		keyboard: true,

	});
}

$(".init-swiper4").on('click', function(){
    initSwiperMainFour('#swiper4');
	//initSwiper2();
});