import 'nodecloud-particleground';

var pg = particleground(document.getElementById('header'), {

    particleRadius: 3,       // размер частицы
    dotColor: '#fff',     // цвет частицы
    density: 14000,          // плотность частиц

    lineColor: '#fff',    // Цвет линии соединения
    lineWidth: 0.2,            // Ширина линии соединения
    curvedLines: false,      // Установить соединительную линию в виде кривой

    minSpeedX: 0.1,          // минимальная скорость по оси X
    maxSpeedX: 0.7,          // максимальная скорость по оси X
    minSpeedY: 0.1,          // минимальная скорость по оси Y
    maxSpeedY: 0.7,          // максимальная скорость по оси Y

    directionX: 'center',    // Ось X направления движения частицы, центр (отскок края), влево, вправо
    directionY: 'center',    // Ось Y направления движения частицы, центр (отскок края), вверх, вниз
    proximity: 115,          // расстояние интерактивного захвата частиц

    parallax: false,          // Включаем эффект параллакса
    parallaxMultiplier: 10,   // Множитель параллакса

    onInit: function() {},   // Выполнить после инициализации
    onDestroy: function() {} // Выполнить после отмены привязки элемента

});

pg.pause();   // Приостановить анимацию частиц

pg.start();   // запустить анимацию частиц

var pg = particleground(document.getElementById('footer'), {

    particleRadius: 3,       // размер частицы
    dotColor: '#fff',     // цвет частицы
    density: 14000,          // плотность частиц

    lineColor: '#fff',    // Цвет линии соединения
    lineWidth: 0.2,            // Ширина линии соединения
    curvedLines: false,      // Установить соединительную линию в виде кривой

    minSpeedX: 0.1,          // минимальная скорость по оси X
    maxSpeedX: 0.7,          // максимальная скорость по оси X
    minSpeedY: 0.1,          // минимальная скорость по оси Y
    maxSpeedY: 0.7,          // максимальная скорость по оси Y

    directionX: 'center',    // Ось X направления движения частицы, центр (отскок края), влево, вправо
    directionY: 'center',    // Ось Y направления движения частицы, центр (отскок края), вверх, вниз
    proximity: 115,          // расстояние интерактивного захвата частиц

    parallax: false,          // Включаем эффект параллакса
    parallaxMultiplier: 10,   // Множитель параллакса

    onInit: function() {},   // Выполнить после инициализации
    onDestroy: function() {} // Выполнить после отмены привязки элемента

});

pg.pause();   // Приостановить анимацию частиц

pg.start();   // запустить анимацию частиц