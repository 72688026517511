import { Notification } from '../components/Notification';
import { Popup } from '../components/Popup';
import {overflowHiddenCallbacks} from '../utils/constants';

/* main menu */
export const mainNav = new Notification({
    popupSelector: '.burger-menu',
    popupActiveClass: 'burger-menu_active'
},{
    notificationWrapperSelector: '.burger-menu__container',
    notificationTogglerSelector: '.burger-header',
    handler: (data) => {
        data.notification.addEventListener('click', e => {
            if(e.target == e.currentTarget){
                mainNav.close();
            };
        });
    }
});
mainNav.handleNotification(overflowHiddenCallbacks.setStyle,overflowHiddenCallbacks.removeStyle);
mainNav.setEventListeners();


const openPopupForm = document.querySelector('.popup-form-open');

const popupForm = new Popup({
    popupSelector: '.popup-form',
    popupActiveClass: 'popup-form_active',
    popupCloseBtnSelector: '.popup-close_text',
})
popupForm.setEventListeners();

openPopupForm.addEventListener('click', () => {
    popupForm.toggle();
});

