
export class Popup {
	constructor({ popupSelector, popupActiveClass, popupCloseBtnSelector }) {
		this._popup = document.querySelector(popupSelector),
			this._popupActiveClass = popupActiveClass,
			this._popupCloseBtnArr = Array.from(
				document.querySelectorAll(popupCloseBtnSelector)
			),
			this._handleEscClose = this._handleEscClose.bind(this);
	}

	_handleEscClose(e) {
		if (e.key == "Escape") {
			this.close();
			console.log(e.key);
		}
	}

	setEventListeners() {
		if (this._popupCloseBtnArr.length) {
			this._popupCloseBtnArr.forEach((popupCloseBtnArrEl) => {
				popupCloseBtnArrEl.addEventListener("click", () => {
					this.close();
				});
			});
		};

		// window.addEventListener('click', (e)=> {
		// 	const click = e.composedPath().includes(this._popup);
		// 	if (!click) {
		// 		this._popup.classList.remove(this._popupActiveClass);
		// 	}
		// })
	}

	open() {
		this._popup.classList.add(this._popupActiveClass);
		document.addEventListener("keydown", this._handleEscClose);
	}

	toggle() {
		this._popup.classList.toggle(this._popupActiveClass);
		document.addEventListener("keydown", this._handleEscClose);
	}

	close() {
		this._popup.classList.remove(this._popupActiveClass);
		document.removeEventListener("keydown", this._handleEscClose);
	}
}
