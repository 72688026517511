import $ from  'jquery';

$(".locationBtn").on('click', function(){
    $("#location").fadeOut(1000);
	  $(".location-map").add('map--hidden');
    $(".location__close").addClass('visible');
});

$(".location__close").on('click', function(){
  $(".location-map").fadeOut(1000);
  $("#location").fadeIn(1000);
  $(".location-map").fadeIn(1000);
  $(".location__close").removeClass('visible');
  $('.loc-map[data-counter="'+$(this).parent().attr('data-counter')+'"]').find('.location__close').removeClass('active-map');
});

$(".btn-toggle").on('click', function(){
  $(".footer__bottom").fadeToggle(1000);
  $(".footer").addClass('resize');
});

$(".item-tab-1").on('click', function(){
  $(".toggle-map-1").toggleClass('map-1');
  $(".toggle-map-2").removeClass('map-2');
});

$(".item-tab-2").on('click', function(){
  $(".toggle-map-2").toggleClass('map-2');
  $(".toggle-map-1").removeClass('map-1');
});


$(".tab__1").on('click', function(){
  $(".tab__1").toggleClass('project__tab_nav-ative');
});

$(".tab__2").on('click', function(){
  $(".tab__2").toggleClass('project__tab_nav-ative');
});

$(".tab__3").on('click', function(){
  $(".tab__3").toggleClass('project__tab_nav-ative');
});

$(".tab__4").on('click', function(){
  $(".tab__4").toggleClass('project__tab_nav-ative');
});

$(".toggle").on('click', function(){
  $(".toggle").toggleClass('open');
});

$(".card-news__item-resize").on('click', function(){
  $(".sticky-bg").fadeIn(1000);
});



$('.product__tab_open_first').click(function(){
  $('.product__tab_open_first').addClass('open');
  $('.product__tab_open_second').removeClass('open');
})

$('.product__tab_open_second').click(function(){
  $('.product__tab_open_second').addClass('open');
  $('.product__tab_open_first').removeClass('open');
})



